import React, { ReactNode, useState } from 'react';
import '../../assets/css/bootstrap-icons/bootstrap-icons.min.css';
import '../../assets/css/style.css';
import ThemeSwitcher from './ThemeSwitcher';
import { useAuth } from '../../AuthContext';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [showSignInForm, setShowSignInForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  const toggleMobileNav = () => {
    setMobileNavActive(!mobileNavActive);
  };

  const handleSignIn = (e: React.FormEvent) => {
    e.preventDefault();
    if (username && password) {
      setIsAuthenticated(true);
      setShowSignInForm(false);
    } else {
      alert('Please enter both username and password');
    }
  };

  const handleSignOut = () => {
    setIsAuthenticated(false);
    setUsername('');
    setPassword('');
  };

  return (
    <div>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <a href="/" className="logo">
            <img src="https://buylowbucket.s3.amazonaws.com/assets/img/logo.png" alt="" className="img-fluid" />
          </a>
          <nav id="navbar" className={`navbar ${mobileNavActive ? 'navbar-mobile' : ''}`}>
            <ul>
              <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a className="nav-link scrollto" href="#about">About</a></li>
              <li><a className="nav-link scrollto" href="#tools">Tools</a></li>
              <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
              <li><a className="nav-link scrollto" href="#faq">FAQ</a></li>
              {/*
              <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
              */}
              {isAuthenticated ? (
                <li><button className="getstarted scrollto" onClick={handleSignOut}>Sign Out</button></li>
              ) : (
                <li><button className="getstarted scrollto" onClick={() => setShowSignInForm(true)}>Sign In</button></li>
              )}
              <li>
                <ThemeSwitcher />
              </li>
            </ul>
            <i
              className={`bi ${mobileNavActive ? 'bi-x' : 'bi-list'} mobile-nav-toggle`}
              onClick={toggleMobileNav}
            ></i>
          </nav>
        </div>
      </header>
      {children}
      {showSignInForm && !isAuthenticated && (
        <div className="sign-in-modal">
          <div className="sign-in-form">
            <h2>Sign In</h2>
            <form onSubmit={handleSignIn}>
              <div className="form-group">
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn-signin">Sign In</button>
                <button type="button" className="btn-cancel" onClick={() => setShowSignInForm(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainLayout;