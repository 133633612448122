import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { AuthProvider } from './AuthContext';
import MainLayout from './components/home/MainLayout';
import Hero from './components/home/Hero';
import About from './components/home/About';
import Testimonials from './components/home/Testimonials';
import Tools from './components/home/Tools';
import CalltoAction from './components/home/CallToAction';
import Pricing from './components/home/Pricing';
import Faq from './components/home/FAQ';
// import Contact from './components/home/Contact';
import UseMainJs from './hooks/useMainJs';
import BestBallDashboard from './components/best-ball/BestBallDashboard';
import GolfDashboard from './components/golf/GolfDashboard';

Amplify.configure({
  Auth: {
      Cognito: {
          userPoolId: 'us-east-1_Nz6MqQ37o',
          userPoolClientId: '5dcp6ptg1j6620vf47strup9bq',
      }
  }
});

const Home: React.FC = () => (
  <MainLayout>
    <Hero />
    <About />
    <Testimonials />
    <Tools />
    <CalltoAction />
    <Pricing />
    <Faq />
    {/* <Contact /> */}
  </MainLayout>
);

const BestBall: React.FC = () => (
  <MainLayout>
    <BestBallDashboard />
  </MainLayout>
);

const Golf: React.FC = () => (
  <MainLayout>
    <GolfDashboard/>
  </MainLayout>
);

const App: React.FC = () => {
  UseMainJs();

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/best-ball" element={<BestBall />} /> */}
          {/* <Route path="/golf" element={<Golf />} /> */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;