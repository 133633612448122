import React, { useState, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

interface RoundData {
  course_name: string;
  event_id: number;
  year: number;
  score: number;
  driving_acc?: number;
  driving_dist?: number;
  gir?: number;
  scrambling?: number;
  sg_total?: number;
  round_number: number;
}

interface PlayerData {
  player_name: string;
  dg_id: number;
  round_data: RoundData;
  year: number;
  event_name: string;
}

interface PlayerPerformanceTrendProps {
  golfData: PlayerData[];
}

const PlayerPerformanceTrend: React.FC<PlayerPerformanceTrendProps> = ({ golfData }) => {
  const [selectedPlayer, setSelectedPlayer] = useState<string>('');

  const players = useMemo(() => {
    const uniquePlayers = new Set(golfData.map(data => data.player_name));
    return Array.from(uniquePlayers).sort();
  }, [golfData]);

  const playerData = useMemo(() => {
    return golfData.filter(data => data.player_name === selectedPlayer);
  }, [golfData, selectedPlayer]);

  const trendData = useMemo(() => {
    const labels = playerData.map(data => `${data.event_name} (${data.year})`);
    const scores = playerData.map(data => data.round_data.score);
    const sgTotals = playerData.map(data => data.round_data.sg_total);
    const drivingAccs = playerData.map(data => (data.round_data.driving_acc ?? 0) * 100);
    const girs = playerData.map(data => (data.round_data.gir ?? 0) * 100);

    return {
      labels,
      datasets: [
        {
          label: 'Score',
          data: scores,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          yAxisID: 'y1',
        },
        {
          label: 'Strokes Gained Total',
          data: sgTotals,
          borderColor: 'rgba(153, 102, 255, 1)',
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          yAxisID: 'y2',
        },
        {
          label: 'Driving Accuracy (%)',
          data: drivingAccs,
          borderColor: 'rgba(255, 159, 64, 1)',
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          yAxisID: 'y3',
        },
        {
          label: 'Greens in Regulation (%)',
          data: girs,
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          yAxisID: 'y4',
        },
      ],
    };
  }, [playerData]);

  const options = {
    scales: {
      y1: {
        type: 'linear' as const,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Score',
        },
      },
      y2: {
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Strokes Gained Total',
        },
      },
      y3: {
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Driving Accuracy (%)',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y4: {
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Greens in Regulation (%)',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <div className="card player-performance-trend-card">
      <h2>Player Performance Trend</h2>
      <div>
        <label htmlFor="player-select">Select Player: </label>
        <select id="player-select" value={selectedPlayer} onChange={(e) => setSelectedPlayer(e.target.value)}>
          <option value="">--Select Player--</option>
          {players.map(player => (
            <option key={player} value={player}>{player}</option>
          ))}
        </select>
      </div>
      {selectedPlayer && playerData.length > 0 && (
        <Line data={trendData} options={options} />
      )}
    </div>
  );
};

export default PlayerPerformanceTrend;